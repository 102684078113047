<template>
  <el-collapse v-model="filter" @change="handleChange">
    <el-collapse-item title="Фильтр" name="p1">
        <template v-slot:title>
          <span>
            <span>Фильтр</span>
            <el-button class="ml-5"
                plain
                size="mini"
                type="default" 
                @click.stop="clearFilters" 
                style="font-family: Montserrat-Medium !important;">
              Очистить фильтры
            </el-button>
          </span>
        </template>
      <el-row :gutter="5" class="mt-1 filter">
        <el-col :span="6" class="ml-2 filter--container">Наименование:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Энергообъект:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Владелец / эксплуатирующая организация:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Энергосистема:</el-col>
      </el-row>

      <el-row class="mt-1 mb-1 filter">
        <el-col :span="6" class="ml-2 filter--container">
          <remote-search-input
            v-model="filterModel.nameFilter"
            searchUrl="/api/primaryEquipments/SearchByNameOnlyLines"
            :start-length=3
          />
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <remote-search-input
            v-model="filterModel.energyObjectIdFilter"
            searchUrl="/api/energyObjects/SearchByName"
            :disabled="filterModel.skipEoObject"
            :allow-create=false
            :start-length=3
            :multiple="true"
          />
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <remote-search-input
            v-model="filterModel.ownerOrganizationsFilter"
            searchUrl="/api/Organizations/SearchByOwner"
            :disabled="filterModel.skipEoObject"
            :allow-create=false
            :multiple=true
            :start-length=3
            returnProp="id"
          />
        </el-col>

        <el-col :span="6" class="ml-2 filter--container big-label">
          <!-- <remote-search-input size="small"
              v-model="filterModel.eoRegionFilter"
              searchUrl="/api/regions/SearchByName"
              :allow-create=false
              :multiple=true
              :start-length=3
              returnProp="id"
            /> -->
          <el-select multiple filterable clearable size="small"
                     ref="eoRegionFilterSelector"
                     :collapse-tags="filterModel.eoRegionFilter.length > 5"
                     :disabled="filterModel.skipEoObject"
                     v-model="filterModel.eoRegionFilter"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.eoRegionFilter = getSelectedIdsByName(operationalZones, $refs.eoRegionFilterSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in operationalZones"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row :gutter="5" class="mt-1 filter">
        <el-col :span="6" class="ml-2 filter--container">Диспетчерское управление:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Диспетчерское ведение:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Напряжение:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Скрыть зарубежные объекты:</el-col>
      </el-row>

      <el-row class="mt-1 mb-1 filter">
        <el-col :span="6" class="ml-2 filter--container">
          <!-- <remote-search-input size="small"
            v-model="filterModel.duOrganizationName"
            searchUrl="/api/organizations/SearchByNameAndRole?roleName=Диспетчерское управление"
            :allow-create=false
            :multiple="true"
            :start-length=3
          /> -->
          <el-select multiple filterable clearable size="small"
                     ref="duOrganizationsSelector"
                     :collapse-tags="filterModel.duOrganizationName.length > 2"
                     v-model="filterModel.duOrganizationName"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.duOrganizationName = getSelectedValues(duOrganizations, $refs.duOrganizationsSelector.query, (item) => (item.value), (item) => (item.value))">
                Выбрать всё
              </el-button>
            </div>
            <el-option v-for="item in duOrganizations"
                       :key="item.id"
                       :label="item.value"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <el-select multiple filterable clearable size="small"
                     ref="dvOrganizationsSelector"
                     :collapse-tags="filterModel.dvOrganizationName.length > 2"
                     v-model="filterModel.dvOrganizationName"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.dvOrganizationName = getSelectedValues(dvOrganizations, $refs.dvOrganizationsSelector.query, (item) => (item.value), (item) => (item.value))">
                Выбрать всё
              </el-button>
            </div>
            <el-option v-for="item in dvOrganizations"
                       :key="item.id"
                       :label="item.value"
                       :value="item.value">
            </el-option>
          </el-select>
          <!-- <remote-search-input size="small"
            v-model="filterModel.dvOrganizationName"
            searchUrl="/api/organizations/SearchByNameAndRole?roleName=Диспетчерское ведение"
            :allow-create=false
            :start-length=3
            :multiple="true"
          /> -->
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2" style="min-width:13rem;">Напряжение оборудования:</span> -->
          <el-select multiple filterable clearable size="small"
                     ref="baseVoltageSelector"
                     :collapse-tags="filterModel.baseVoltageFilter.length > 5"
                     v-model="filterModel.baseVoltageFilter">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.baseVoltageFilter = getSelectedValuesByName(sortedDescBaseVoltages, $refs.baseVoltageSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in sortedDescBaseVoltages"
              :key="item.id"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <el-switch style="width: 100%;"
              v-model="filterModel.isRuFilter"
              :disabled="filterModel.skipEoObject"
              active-color="#13ce66"
              inactive-color="#ff4949"
            />
        </el-col>
      </el-row>

      <el-row :gutter="5" class="mt-1 filter">
        <el-col :span="6" class="ml-2 filter--container">Технологическое управление:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Технологическое ведение:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Отображать объекты локальной НСИ:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Показать записи без привязки к ЭО:</el-col>
      </el-row>

      <el-row class="mt-1 mb-1 filter">
        <el-col :span="6" class="ml-2 filter--container">
          <!-- <remote-search-input size="small"
            v-model="filterModel.techAdministrationOrganizationsFilter"
            searchUrl="/api/organizations/SearchByNameAndRole?roleName=Технологическое управление"
            :allow-create=false
            :start-length=3
            :multiple="true"
            returnProp="id"
          /> -->
          <el-select multiple filterable clearable size="small"
                     ref="taOrganizationsSelector"
                     :collapse-tags="filterModel.techAdministrationOrganizationsFilter.length > 2"
                     v-model="filterModel.techAdministrationOrganizationsFilter"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.techAdministrationOrganizationsFilter = getSelectedValues(techAdministrationOrganizations, $refs.taOrganizationsSelector.query, (item) => (item.value), (item) => (item.id))">
                Выбрать всё
              </el-button>
            </div>
            <el-option v-for="item in techAdministrationOrganizations"
                       :key="item.id"
                       :label="item.value"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <remote-search-input size="small"
            v-model="filterModel.techManagementOrganizationsFilter"
            searchUrl="/api/organizations/SearchByNameAndRole?roleName=Технологическое ведение"
            :allow-create=false
            :start-length=3
            :multiple="true"
            returnProp="id"
          /> -->
          <el-select multiple filterable clearable size="small"
                     ref="tmOrganizationsSelector"
                     :collapse-tags="filterModel.techManagementOrganizationsFilter.length > 2"
                     v-model="filterModel.techManagementOrganizationsFilter"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.techManagementOrganizationsFilter = getSelectedValues(techManagementOrganizations, $refs.tmOrganizationsSelector.query, (item) => (item.value), (item) => (item.id))">
                Выбрать всё
              </el-button>
            </div>
            <el-option v-for="item in techManagementOrganizations"
                       :key="item.id"
                       :label="item.value"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <el-switch style="width: 100%;"
              v-model="filterModel.isLocalNsiFilter"
              active-color="#13ce66"
              inactive-color="#ff4949"
            />
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <el-switch style="width: 100%;"
              v-model="filterModel.skipEoObject"
              active-color="#13ce66"
              inactive-color="#ff4949"
            />
        </el-col>
      </el-row>
      <!-- <el-row class="m-0 filter">
        <el-col :span="8" class="ml-2 filter--container">
          <el-button @click="clearFilters" type="text" style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </el-col>
      </el-row> -->
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters } from 'vuex';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import UserSettingsService from '@/service/userSettingsService';
import organizationsApi from '@/api/nsi/organizations';
import filterHelper from '@/mixins/filterHelper';

export default {
  name: 'LinesFilter',
  props: {
    filterModel: { type: Object, default: () => {} },
  },
  mixins: [filterHelper],
  components: { RemoteSearchInput },
  data() {
    return {
      filter: [''],
      primaryEquipmentTypes: [],
      techAdministrationOrganizations: [],
      techManagementOrganizations: [],
      dvOrganizations: [],
      duOrganizations: []
    };
  },
  async created() {
    if (UserSettingsService.has('linesFilter_filter_open', this.user.id)) {
      this.filter = ['p1'];
    }
    const techAdmOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Технологическое управление');
    if (techAdmOrgs.data) {
      this.techAdministrationOrganizations = techAdmOrgs.data;
    }
    const techManOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Технологическое ведение');
    if (techManOrgs.data) {
      this.techManagementOrganizations = techManOrgs.data;
    }
    const dvOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Диспетчерское ведение');
    if (dvOrgs.data) {
      this.dvOrganizations = dvOrgs.data;
    }
    const duOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Диспетчерское управление');
    if (duOrgs.data) {
      this.duOrganizations = duOrgs.data;
    }
  },
  computed: {
    ...mapGetters('dictionaries', ['baseVoltages', 'operationalZones']),
    ...mapGetters('identity', ['user']),
    sortedDescBaseVoltages() {
      const baVoWithDefault = [...this.baseVoltages, { id: '', name: 'Без напряжения', code: 0 }];
      return baVoWithDefault.sort((a, b) => b.code - a.code);
    }
  },
  methods: {
    handleChange(val) {
      if (val.find((el) => el === 'p1')) {
        UserSettingsService.set('linesFilter_filter_open', true, this.user.id);
      } else {
        UserSettingsService.delete('linesFilter_filter_open', this.user.id);
      }
      const objectLength = Object.keys(val);
      if (objectLength.length > 0) {
        this.$emit('active-change', true);
       } else {
        this.$emit('active-change', false);
      }
    },
    clearFilters() {
      this.$emit('clear-filters');
    },
  }
};
</script>

<style scoped lang="scss">
.filter--container{
  align-items: flex-start;
  span {
    min-width: 8rem;
  }
}
.middle-align{
  align-items:center;
}
</style>
